<template>
  <div style="position: relative; height: 100%; display: flex; flex-direction: column;">
    <div style="padding: 10px 2.6% 0;">
      <div style="display: flex; align-items: center;">
        <van-icon 
          name="arrow-left" 
          size="20" 
          style="font-weight: bold;" 
          @click="rollback"
        />
        <div class="Ge-fangdajing" @click="$router.push({
          path:'/search',
          query: {
            shopId: $route.query.shopId
          }
        })">
          <i style="font-size: 16px; font-weight: bold;" class="iconfont icon-fangdajing"></i>
          <span>输入商品名</span>
        </div>
        <van-icon 
          name="chat-o" size="20" style="font-weight: bold;"
          @click="contact(im)"
        />
        <van-icon
          @click="collectShopping(0)"
          v-if="collectShoppingNum%2==0"
          name="star-o" 
          size="20" 
          style="font-weight: bold; margin: 0 2.6%;"
        />
        <van-icon
          @click="collectShopping(1)"
          v-if="collectShoppingNum%2==1"
          name="star"
          size="20"
          color="#FF3C00"
          style="font-weight: bold; padding: 0 2.6%;"
        />
        <i 
          style="font-size: 20px; font-weight: bold;" class="iconfont icon-fenxiang"
          @click="shareBox = true"
        ></i>
      </div>

      <div style="padding: 10px 0 15px; margin-top: 10px;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
          <div style="flex: 1;">
            <div style=" border: 1px solid transparent; display: flex; align-items: center;">
              <div style="color: #333333; font-weight: 600; margin-right: 10px;">
                {{ detail.shopName }}
              </div>
              <div style="font-size: 12px; 
                color: #FF5200;
                border: 1px solid #FF5200;
                border-radius: 3px;
                display: flex;
                align-items: center;
                padding: 0 5px;
                justify-content: center;
                transform: scale();"
              >
                <span style="transform: scale(.83) translateY(1px);">
                  {{ 
                    currentOperatingState == 0 ? '休息中' : currentOperatingState == 1 ? '营业中' : ''
                  }}
                </span>
              </div>
            </div>
            <div style="display: flex; align-items: center; margin: 2px 0 7px;">
              <van-rate 
                color="#FF5200"
                v-model="detail.commentNumber" 
                allow-half
                readonly
                void-icon="star" 
                void-color="#eee" 
              />
              <div style="color: #FF5200; font-size: 12px; width: 30px; display: flex; justify-content: space-between; margin-left: 1.6%;">
                  <span>{{ detail.commentNumber }}分</span>
              </div>
              <span style="color: #999; font-size: 12px; margin-left: 2.6%;">月售{{ detail.shopMouthSell }}份</span>
            </div>
            <div style="display: flex; flex-wrap: wrap;">
              <div
                v-for="(item, index) in couponShops"
                :key="index"
                style="background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngeb485d92dd8b8560578540d99d6038d2033f5b846087f4f176a5cc5597e0882a) no-repeat;
                display: flex;
                font-size: 12px;
                margin: 0 1.3% 5px 0;
                height: 22px; width: 73px;"
              >
                <div style="background: ; width: 42px; color: #fff; display: flex; justify-content: center; align-items: center;">
                  <span style="transform: scale(.7) translateY(1px);">￥</span>
                  <span>{{ item.reduce }}</span>
                  </div>
                <div 
                  style="width: 29px; 
                  color: #ffd8c6; 
                  display: flex; 
                  justify-content: center; 
                  align-items: center;"
                  @click="couponuser(item.id)"
                >领</div>
              </div>
            </div>
          </div>
          <img style="width: 72px; height: 72px;" :src="detail.shopImgUrl" alt="">
        </div>
        <div 
          class="discount"
          v-if="fullReduces.length"
        >
          <div class="Ge-border">
            <div 
              v-for="(item, index) in fullReduces"
              :key="index"
            >
              <span>{{ item.fullCash }}减{{ item.reduce }}</span>
              <i
                v-if="fullReduces.length == index + 1 ? false : true"
                class="iconfont icon-vertical_line" 
                style="font-weight: bold; font-size: 12px;"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 选项 -->
    <div style="background: #F6F6F6; display: flex; color: #999;">
      <div 
        style="border-bottom: 1px solid transparent; 
        padding: 10px 0; margin-left: 4.3%; 
        margin-right: 11.4%;" 
        @click="tabs(1)" 
        :class="{active: active==1}"
      >点菜</div>
      <div 
        style="position: relative; 
          padding: 10px 0; 
          border-bottom: 1px solid transparent;" 
        @click="tabs(2)" 
        :class="{active: active==2}"
      >
        <div>评价</div>
        <div 
          style="transform: scale(.83);
            position: absolute;
            left: 28px;
            top: 14px;
            font-size: 12px;"
        >{{ evaluateTotal }}</div>
      </div>
    </div>

    <div class="takeOut" v-if="active == 1 ? true : false">
      <div class="takeOut-shopCate">
        <div class="takeOut-shopCate-cateOption">
          <div
            :class="['takeOut-shopCate-cateOption-name', { cateActive: cateActive == index }]"
            v-for="(item, index) in shopCateName"
            :key="index"
            @click="clickCate(index, item.groupName)"
          >
            <div>{{ item.groupName }}</div>
          </div>
        </div>
      </div>

      <div class="takeOut-shopList">
        <p>{{ shopName }}商品</p>
        <div class="takeOut-shopList-option">
          <div
            class="takeOut-shopList-option-name" 
            v-for="(item, index) in shops"
            :key="index"
            @click="clickShop(item.goodsId)"
          >
            <div class="takeOut-shopList-option-name-box">
              <img
                style="width: 92px; height: 92px;"
                :src="item.goodsImages[0]" alt="">
              <div 
                class="takeOut-shopList-option-name-right" 
              >
                <div style="font-weight: bold;">{{ item.goodsName }}</div>
                <div style="margin: 3px 0 5px;">
                  <van-tag
                    style="margin-right: 5px;"
                    color="#e8e8e8" 
                    v-for="(item2, index2) in item.standardName" 
                    :key="index2"
                    text-color="#333"
                  >
                    {{ item2.standardContent }}
                  </van-tag>
                </div>
                <div style="width: 63px; color: #333; font-size: 12px; transform: scale(.8) translateX(-8px);">月售{{ item.goodsMouthSell }}份</div>
                <div style="display: flex;" v-if="item.noSingle ==1 ? true : false">
                  <div style="font-size: 12px; transform: scale(.8) translateX(-8px); border: 1px solid #333; padding: 1px 10px; border-radius: 5px;">
                    {{ item.noSingle == 1 ? '单点不送' : '' }}
                  </div>
                </div>
                <div style="display: flex; justify-content: space-between; align-items: flex-end;">
                  <div>
                    <span style="color: red;">
                      <span style="font-size: 12px;">￥</span>
                      <span>
                        {{ 
                          item.discountPrice ? (item.discountUser == 0 ? item.discountPrice: (flag == 0 ? item.discountPrice : item.price)) : item.price
                        }}
                      </span>
                    </span>
                    <span 
                      v-if="item.discountPrice ? item.discountUser == 0 ? true : flag == 0 ? true : false : false"
                      style="color: red; color: #999; text-decoration: line-through; margin-left: 5px;"
                    >
                      <span
                        style="font-size: 12px; font-size: 12px;"
                      >￥</span>{{ item.price }}
                    </span>
                  </div>

                  <div
                    class="bujinqi"
                    v-if="item.standardName.length == 0"
                    @click.stop=""
                  >
                    <van-stepper 
                      v-model="shopStepper"
                      disable-input
                      min="0"
                      button-size="19"
                      input-width="19"
                      :show-minus='showMinus'
                    />
                  </div>

                  <div style="display: flex; flex-direction: column; background: rgb(246, 245, 240);">
                    <div
                      v-if="item.minBuy == 1 || item.minBuy == null || item.minBuy == '' ? false : true"
                      style="foot-size: 12px; 
                      display: flex; 
                      justify-content: center; 
                      align-items: center;
                      color: rgb(140, 140, 140);
                      transform: scale(.75);"
                    >
                      <span style="">{{ item.minBuy }}份起购</span>
                    </div>
                    <van-button 
                      v-if="item.standardName.length"
                      size="mini" 
                      color="#FF5200" 
                      @click.stop="selectSpecification(item.goodsName,item.goodsId,
                      item.goodsImages[0],
                      item.discountPrice,
                      item.price,item.discountUser,
                      item.stockCurrent,item.noSingle,
                      item.minBuy)"
                    >选规格</van-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 评价 -->
    <div 
      v-if="active == 2 ? true : false" 
      class="evaluate"
    >
      <div style="height: 100%; position: relative;">
        <div
          style="display: flex;
            justify-content: space-between;
            position: absolute;
            top: 0;
            width: 100%;
            padding: 10px 0 0;"
        >
          <div style="display: flex; align-items: center;">
            <span style="font-weight: bold;">评价</span>
            <div style="font-size: 12px; transform: scale(.83); margin-top: 3px; color: #999999;">（好评度{{ applauseRate }}%）</div>
          </div>
          <div style="color: #999999; font-size: 14px;">
            <span style="margin-right: 5px;">{{ evaluateTotal }}条评论</span>
            <van-icon name="arrow" />
          </div>
        </div>

        <div 
          class="evaluateBox"
          style="position: absolute; 
            top: 36px;
            bottom: 38px;
            overflow: auto;
            width: 100%;"
        >
          <div>
            <div
              style="display: flex; 
                justify-content: space-between; 
                border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
                padding: 9px 0 16px;"
              v-for="(item, index) in evaluateContent"
              :key="index"
            >
              <img
                style="width: 55px; height: 55px;"
                :src="item.appraiseHeader" alt="">
              <div style="width: 78%;">
                <div style="display: flex; justify-content: space-between;">
                  <span style="font-size: 14px;">{{ item.appraiseName }}</span>
                  <span  style="font-size: 12px; color: #999;">{{ item.appraiseTime }}</span>
                </div>
                <p style="color: #999; font-size: 12px;">{{ item.appraiseContent }}</p>
              </div>
            </div>
          </div>
        </div>

        <div 
          style="
            height: 17px;
            padding: 10px 0;
            text-align: center;
            width: 100%;
            position: absolute;
            bottom: 0;
            font-size: 12px; color: #333;"
        >查看全部{{ evaluateTotal }}条评论 ></div>
      </div>
    </div>

    <div class="bottom-GoodsAction" v-if="active == 1 ? true : false">
      <van-goods-action>
        <van-goods-action-icon icon="cart-o" text="购物车" @click="clickCart" />
        <div 
          style="border-radius: 20px 0 0 20px;
          width: 60%;
          position: relative;
          box-sizing: border-box;        
          background: #313131; 
          height: 40px;"
        >
          <div
            style="display: flex;
            top: 3px;
            transform: translateX(15px);
            position: absolute;
            align-items: center;"
          >
            <div style="color: #fff;">
              <span style="font-size: 12px;">￥</span>
              <span style="font-size: 14px;">{{ shopPrice[1] }}</span>
            </div>
            <div style="font-size: 12px; transform: scale(.83); margin-top: 3px; color: #999;">￥{{ shopPrice[0] }}</div>
          </div>
          <div
            style="font-size: 12px;
            position: absolute;
            bottom: 0px;
            left: 15px;
            color: #fff;"
          >
            <span>预估配送费￥{{ transportUserPrice }}</span>
            <span
              style="transform: scale(.83); margin-left: 5px; color: #999;"
              v-if="transportStartPriceResult > 0 ? true : false"
            >差￥{{ transportStartPriceResult }}起送</span>
            <span
              style="transform: scale(.83); margin-left: 5px; color: #999;"
            >
              {{
                transportStartPriceResult > 0 ? '' : noDistribution == true ? '单点不配送' : ''
              }}
            </span>
          </div>
        </div>
        <van-goods-action-button
          type="danger"
          :disabled="disabled"
          text="去结算"
          @click="goSettle"
        />
      </van-goods-action>
    </div>

    <!-- 购物车面板 -->
    <div>
      <van-action-sheet v-model="show">
      <!-- <van-action-sheet v-model="show" title="购物车" close-icon="delete-o"> -->
        <div style="padding: 0 4% 51px; display: flex; flex-direction: column; height: 100%;">
          <div
            style="position: relative;
              padding: 15px 0;
              background: #fff;"
            >
            <p 
              style="font-size: 15px;
              color: #333; text-align: center;"
            >购物车</p>
            <div
              style="color: #999; 
              position: absolute; 
              right: 0;
              display: flex;
              align-items: center;
              top: 15px;"
              @click="emptyShopCar"
            >
              <van-icon name="delete-o" size="20"/>
              <span 
                style="font-size: 12px; margin-left: 3px; padding-top: 2px;"
              >清空购物车</span>
            </div>
          </div>

            <!-- 购物车商品 -->
          <div class="shopCartShop">
            <div
              style="display: flex; 
                justify-content: space-between; 
                padding: 10px 0;"
                v-for="(item, index) in shopCar"
                :key="item.nanoid"
            >
              <img style="width: 93px; height: 93px;" :src="item.goodsImages" alt="">
              <div style=" width: 69%;">
                <p style="font-size: 13px; color: #333;">{{ item.goodsName }}</p>
                <p style="font-size: 12px; color: #999; margin-top: 5px;">
                  <span
                    style="margin-right: 5px;"
                    v-for="(item2, index2) in item.specificationShow"
                    :key="index2"
                  >{{ item2 }}</span>
                </p>
                <div 
                  style="display: flex; 
                    justify-content: space-between;
                    margin-top: 10px;
                    align-items: center;">
                  <div style="display: flex;">
                    <div style="display: flex; margin-right: 5px; color: #A80000;">
                      <div style="font-size: 12px; transform: scale(.8); margin-top: 3px;">￥</div><span>{{ (item.price * item.quantity).toFixed(2) }}</span>
                    </div>
                    <div style="display: flex; text-decoration: line-through; color: #999;">
                      <div style="font-size: 12px; transform: scale(.8); margin-top: 3px;">￥{{ (item.originalPrice * item.quantity).toFixed(2) }}</div>
                    </div>
                  </div>
                  <div>
                    <van-stepper 
                      v-model="item.quantity" 
                      disable-input
                      min="0"
                      button-size="19"
                      input-width="19"
                      @change="shopCarChange(index,$event)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-action-sheet>
    </div>

    <!-- 选规格弹框 -->
    <div class="shopSpecification">
      <van-dialog 
        v-model="dialogShow"
        width="89%"
        :close-on-click-overlay="true"
        :showConfirmButton="false"
      >
        <div style="padding: 20px 5.3%; 0">
          <p style="text-align: center; color: #333;">{{ goodsName }}</p>
          <div>
            <div 
              v-for="(item, index) in standard" :key="index"
              style="margin-bottom: 3px;"
            >
              <p style="font-size: 12px; color: #333; margin: 6px 0 3px;">{{ item.name }}</p>
              <div 
                style="display: flex;"
              >
                <div
                  v-for="(item2, index2) in item.contenList"
                  :key="index2"
                  :class="{copies: copies[index] == index2}"
                  @click="cppiesTabs(index, index2, item2.standardName, item2.addPrice, item2.standardId, item2.id)"
                  style="width: 85px; 
                  height: 30px; font-size: 12px;
                  border: 1px solid #999;
                  display: flex;
                  margin: 0 4% 0 0;
                  align-items: center;
                  justify-content: center;
                  border-radius: 5px;"
                >{{ item2.standardName }}</div>
              </div>
            </div>
          </div>
        </div>

        <div
          style="font-size: 12px; #999;
            display: flex;
            padding-left: 5.3%;
            margin-top: 20px;
            box-sizing: border-box;
            align-items: center;
            background: #F6F6F6; 
            height: 33px;"
        >
          <span style="color: #999;">
            <span>已选规格：</span>
            <span style="color: #333;" v-for="(item, index) in specificationShow" :key="index">
              {{ item }} {{ specificationShow.length == index + 1 ? '' : '、' }}
            </span>
          </span>
        </div>

        <div class="totalAmount">
          <div style="display: flex; align-items: center;">
            <span style="font-size: 14px; color: #333;">总计</span>&nbsp;
            <div style="font-size: 12px; margin-top: 5px; font-weight: 600;">￥</div>
            <span style="font-size: 24px; color: #333; font-weight: 600; margin-bottom: 3px;">{{ totalPrice.toFixed(2) }}</span>
          </div>

          <van-button
            v-if="shopExist ? false : true"
            icon="plus"
            type="primary"
            size="mini"
            color="linear-gradient(to right, #FF7200, #FF3C00)"
            @click="add_cart_shop"
          >加入购物车</van-button>

          <div v-if="shopExist ? true : false">
            <van-stepper 
              v-model="guigeStepper"
              disable-input
              min="0"
              button-size="19"
              input-width="19"
              @change="stepperChange"
            />
          </div>
        </div>

        <div 
          style="
            width: 39px; height: 39px;
            border: 1px solid #fff;
            background: rgba(147, 147, 147, .4);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 50%;
            bottom: -65px;
            transform: translateX(-50%);
          "
          @click="dialogShow = false"
        >
          <van-icon 
            size="30" 
            name="cross"
            color="#fff"
          />
        </div>
      </van-dialog>
    </div>

    <share-box v-model="shareBox" :data="data" />
  </div>
</template>

<script>
import { 
  takeOutDetail, couponuser,
  cateShop, shopevaluate, 
  shopCollect, cancelShopCollect, 
  selectStandards, newuserflag } from '@/api/Ge'
import qs from 'qs'
import { mapState, mapMutations } from 'vuex'
import { contact } from '@/common'
import shareBox from './share.vue'
import { nanoid } from 'nanoid'
  export default {
    components: {
      shareBox
    },
    data () {
      return {
        shareBox: false,
        currentOperatingState: '',
        data: {},
        collectShoppingNum: 0, // 收藏
        value: '', // 搜索框 
        grade: 0, // 评价
        active: 1, // 选项
        cateActive: 0,
        show: false,
        stepper: '', // 规格步进器
        shopStepper: 0, // 商品步进器
        showMinus: false, // 隐藏减少按钮
        dialogShow: false,
        standard: [], // 规格
        transportStartPrice: '', // 起送价
        copies: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 份数
        detail: {},
        shopCateName: [],
        shops: [],
        shopName: '', // 商品列表标题
        goodsName: '', // 规格商品名称
        goodsId: '', // 规格框 判断购物车 步进器
        goodsImages: '', // 商品列表图片
        cartShow: true,
        stepperShow: false,
        cartArr: [], // 购物车商品
        evaluateTotal: null, //评价总条数
        evaluateContent: [], // 评价内容
        applauseRate: '', // 好评度
        fullReduces: [], // 满减券
        couponShops: [], // 优惠券
        specificationShow: [], // 已选规格

        totalPrice: 0, // 总价
        originalPrice: 0, // 商品原价
        originalPrice2: 0, // 商品原价
        basicPrice: 0, // 商品列表基础价格
        priceArr: [], // 价格数组
        specificationArr: [], // 规格数组
        noSingle: null, // 单点不送
        minBuy: null, // 起购数量

        shopExist: false, // 判断商品是否存在
        guigeStepper: 0, // 规格步进器值
        discountPrice: null, // 商品折扣价
        flag: '', // 0-新用户 1 老用户 
        // flag: 0, // 0-新用户 1 老用户
      }
    },

    computed: {
      ...mapState({
        shopCar: state => JSON.parse(JSON.stringify(state.shopCar.shopCar)),
        transportUserPrice: state => state.shopCar.transportUserPrice,
        im: state => state.shopCar.im,
        disabled: state => state.shopCar.disabled,
        session: state => state.session
      }),
      ids () {
        return JSON.parse(JSON.stringify(this.$store.getters['shopCar/goodsIds']))
      },
      shopPrice () {
        return JSON.parse(JSON.stringify(this.$store.getters['shopCar/shopPrice']))
      },
      transportStartPriceResult () { // 起送价结果
        let transportStartPriceResult = parseFloat(this.transportStartPrice) - parseFloat(this.shopPrice[1])
        return transportStartPriceResult.toFixed(2)
      },
      noDistribution () {
        return this.$store.getters['shopCar/noDistribution']
      }
    },

    watch: {
      dialogShow (value) {
        if (!value) {
          this.priceArr = []
          this.specificationArr = []
          this.basicPrice = 0
          this.specificationShow = []
          this.discountPrice = null
          this.noSingle = null
          this.minBuy = null
        } else {
          this.copies = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      },

      shopStepper (value) {
        if (value) {
          this.showMinus = true
        } else {
          this.showMinus = false
        }
      },

      transportStartPriceResult (val) {
        if (val <=0) {
          const noSingleShop = JSON.parse(JSON.stringify(this.shopCar))
          const noSingleShopTrue = noSingleShop.every(item => item.noSingle == '1')
          if (noSingleShopTrue) {
            this['shopCar/set_disabled'](true)
          } else {
            this['shopCar/set_disabled'](false)
          }
        } else {
          this['shopCar/set_disabled'](true)
        }
      }
    },
    
    created () {
      // const id = this.$route.query.id
      // console.log(id, 'id')
      this.$store.commit('setSession', this.$route.query.session || this.session)

      this.newuserflag()
      this.takeOutDetail()
      this.shopEvaluate()
      // console.log(this.$store.state.session, 'session')
    },
    mounted () {
      this['shopCar/lot_lat']({
        lot: this.$route.query.lot,
        lat: this.$route.query.lat
      })
    },
    methods: {
      ...mapMutations([
        'shopCar/change_quantity', 
        'shopCar/add_distance', 
        'shopCar/delete_shop', 
        'shopCar/set_shopCar',
        'shopCar/transport_User_Price',
        'shopCar/lot_lat',
        'shopCar/get_im', 
        'shopCar/empty_shop_car',
        'shopCar/get_sellerAddress',
        'shopCar/get_transportStartPrice',
        'shopCar/get_fullReduces',
        'shopCar/set_disabled',
        'shopCar/set_businussTime',
      ]),

      contact,

      // 跳商品详情
      clickShop (id) {
        this.$router.push({
          path: '/shoppingDetail',
          query: {
            shopId: this.$route.query.shopId,
            id,
            session: this.$route.query.session
          }
        })
      },

      // 店铺评价
      shopEvaluate () {
        shopevaluate('id=' + this.$route.query.shopId).then(res => {
          if (res.data.code === 0) {
            this.evaluateTotal = res.data.data.page.total
            this.evaluateContent = res.data.data.page.records
            this.applauseRate = res.data.data.takeoutContentVO.applauseRate
          }
        })
      },

      // 店铺详情
      takeOutDetail () {
        takeOutDetail('shopId=' + this.$route.query.shopId).then(res => {
          if (res.data.code === 0) {
            this.detail = res.data.data
            this.shopCateName = res.data.data.takeoutGroups
            this.shopName = res.data.data.takeoutGroups[0].groupName
            this.collectShoppingNum = res.data.data.collection
            this.fullReduces = res.data.data.fullReduces
            this.couponShops = res.data.data.couponShops
            this.transportStartPrice = res.data.data.transportStartPrice
            this.currentOperatingState = res.data.data.currentOperatingState
            this.cateShop()
            this['shopCar/add_distance']([res.data.data.longitude, res.data.data.latitude])
            this['shopCar/transport_User_Price'](res.data.data.transportUserPrice)
            this['shopCar/get_im'](res.data.data.imAccid)
            this['shopCar/get_sellerAddress'](res.data.data.address)
            this['shopCar/get_transportStartPrice'](res.data.data.transportStartPrice)
            this['shopCar/get_fullReduces'](res.data.data.fullReduces)
            this['shopCar/set_businussTime'](res.data.data.runHours)
            let url = window.location.href;
            this.data = {
              title: 'Win生活',
              picUrl: res.data.data.shopImgUrl[0],
              content: res.data.data.shopName,
              openUrl:
              url.split("session")[0] +
              "shareUserId=" +
              this.detail.userId +
              "&session" +
              url.split("session")[1],
            }
          }
        })
      },

      // 分类商品
      cateShop () {
        cateShop(qs.stringify({
          shopId: this.$route.query.shopId,
          id: this.shopCateName[this.cateActive].id
        })).then(res => {
          if (res.data.code === 0) {
            this.shops = res.data.data.records
          }
        })
      },

      // 选规格
      selectSpecification (
        goodsName, goodsId, 
        goodsImages, basicPrice, 
        originalPrice,discountUser,
        stockCurrent,noSingle,minBuy) {
        if (stockCurrent == 0) {
          this.$toast('商品已售空')
          return
        }
        this.dialogShow = true
        this.noSingle = noSingle
        this.discountPrice = basicPrice
        this.goodsName = goodsName
        this.goodsId = goodsId
        this.goodsImages = goodsImages
        this.discountUser = discountUser
        this.minBuy = minBuy
        if (basicPrice) {
          // 所有用户折扣价
          if (discountUser == '0') {
            this.basicPrice = basicPrice
          } else if (discountUser == '1') { // 折扣价只针对新用户
            if (this.flag == '0') {
              this.basicPrice = basicPrice
            } else if (this.flag == '1') {
              this.basicPrice = originalPrice
            }
          }
        } else {
          this.basicPrice = originalPrice
        }
        this.originalPrice2 = originalPrice
        selectStandards(qs.stringify({
          shopId: this.$route.query.shopId,
          goodsId
        })).then(res => {
          if (res.data.code === 0) {
            this.standard = res.data.data
            let price = 0
            res.data.data.forEach((item, index) => {
              item.contenList.forEach((item2, index2) => {
                if (index2 === 0) {
                  this.specificationShow[index] = item2.standardName
                  price = price + item2.addPrice
                  this.priceArr.push(item2.addPrice)
                  let params = {
                    standardId: item2.standardId,
                    id: item2.id
                  }
                  this.specificationArr.push(params)
                }
              })
            })
            this.totalPrice = price + this.basicPrice
            this.originalPrice = price + originalPrice // 规格弹框关闭为侦听
            this.judgeShop(this.goodsId, this.specificationArr)
            this.stepperNum(goodsId, this.specificationArr)
          }
        })
      },

      // 规格
      cppiesTabs (index, index2, name, addPrice, standardId, id) {
        this.copies[index] = index2
        this.copies = this.copies.concat([])
        this.specificationShow[index] = name
        this.priceArr[index] = addPrice 
        let price = 0
        this.priceArr.forEach(item => {
          price = price + item
        })
        this.totalPrice = price + this.basicPrice
        this.originalPrice = price + this.originalPrice2
        let params = {
          standardId,
          id
        }
        this.specificationArr[index] = params
        this.specificationArr = this.specificationArr.concat([])
        this.judgeShop(this.goodsId, this.specificationArr)
        this.stepperNum(this.goodsId, this.specificationArr)
      },

      judgeShop (goodsId, specificationArr) {
        const specificationArrId = []
        specificationArr.forEach(item2 => {
          specificationArrId.push(item2.id)
        })
        const ids = JSON.parse(JSON.stringify(this.ids))
        const shopCar  = JSON.parse(JSON.stringify(this.$store.state.shopCar.shopCar))
        if (ids.includes(goodsId)) {
          const numBer = []
          shopCar.forEach(item => {
            const numArr2 = []
            item.allGoods.forEach(item2 => {
              numArr2.push(item2.id)
            })
            const trueFalse = specificationArrId.every( item => numArr2.includes(item))
            if (trueFalse) {
              numBer.push(1)
            } else {
              numBer.push(2)
            }
          })
          if (numBer.includes(1)) {
            this.shopExist = true
          } else {
            this.shopExist = false
          }
        } else {
          this.shopExist = false
        }
      },

      // 渲染规格弹框步进器
      stepperNum (goodsId, specificationArr) {
        const newSpecificationArr = []
        specificationArr.forEach(item => newSpecificationArr.push(item.id))
        const shopCar = JSON.parse(JSON.stringify(this.$store.state.shopCar.shopCar))
        shopCar.forEach(item => {
          const ids = []
          item.allGoods.forEach(item2 => {
            ids.push(item2.id)
          })
          const trueFalse = newSpecificationArr.every(item3 => ids.includes(item3))
          if (trueFalse) {
            this.guigeStepper = item.quantity
          }
        })
      },

      // 步进器
      stepperChange (num) {
        if (num == 0) {
          this['shopCar/delete_shop']({
            num,
            specificationArr: JSON.parse(JSON.stringify(this.specificationArr)),
            goodsId: this.goodsId
          })
        } else {
          this['shopCar/change_quantity']({
            goodsId: this.goodsId,
            specificationArr: this.specificationArr,
            guigeStepper: this.guigeStepper
          })
          this.stepperNum(this.goodsId, this.specificationArr)
        }
        this.judgeShop(this.goodsId, this.specificationArr)
      },

      // 面板步进器
      shopCarChange (index, e) {
        if (e === 0) {
          this.shopCar.splice(index, 1)
        }
        this['shopCar/set_shopCar'](JSON.parse(JSON.stringify(this.shopCar)))
      },

      // 规格弹框 加入购物车
      add_cart_shop () {
        const Goodsnanoid = nanoid()
        let params = {
          nanoid: Goodsnanoid,
          goodsId: this.goodsId,
          goodsName: this.goodsName,
          goodsImages: this.goodsImages,
          price: this.totalPrice,
          originalPrice: this.originalPrice,
          allGoods: this.specificationArr,
          specificationShow: this.specificationShow,
          discountPrice: this.discountPrice,
          noSingle: this.noSingle,
          minBuy: this.minBuy,
          quantity: 1
        }
        params = JSON.parse(JSON.stringify(params))
        this.$store.commit("shopCar/add_shop", params)
        this.judgeShop(this.goodsId, this.specificationArr)
        this.stepperNum(this.goodsId, this.specificationArr)
      },

      // 购物车
      clickCart () {
        if (this.show == false) {
          this.show = true
        } else {
          this.show = false
        }
      },

      emptyShopCar () {
        this['shopCar/empty_shop_car']()
        this.show = false
      },
 
      // 点击分类
      clickCate (index, name) {
        this.cateActive = index
        this.shopName = name
        this.cateShop()
      },

      tabs (num) {
        this.active = num
      },

      // 收藏
      collectShopping (status) {
        this.collectShoppingNum++
        let data = { id: this.$route.query.shopId, flag: 1 }
        if (status == 0) {
          shopCollect(qs.stringify(data)).then(res => {
            if (res.data.code === 0) {
              this.$toast('收藏成功')
            }
          })
        } else if (status == 1) {
          cancelShopCollect(qs.stringify(data)).then(res => {
            if (res.data.code === 0) {
              this.$toast('取消收藏成功')
            }
          })
        }
      },

      // 回退
      rollback () {
        let u = navigator.userAgent
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if(isIOS) {
          window.webkit.messageHandlers.close.postMessage('up');   
        }
        if (isAndroid) {
          window.android.close(); 
        } 
      },

      // 领取优惠券
      couponuser (id) {
        couponuser(qs.stringify({
          couponId: id
        })).then(res => {
          if (res.data.code === 0) {
            this.$toast(
              res.data.data.flag == 1 ? '优惠券' + res.data.data.flag :
              res.data.data.flag == 2 ? '已领取优惠券' : '已领取优惠券'
            )
          }
        })
      },

      // 新用户
      newuserflag () {
        newuserflag(qs.stringify({
          shopId: this.$route.query.shopId
        })).then(res => {
          if (res.data.code === 0) {
            this.flag = res.data.data.flag
          }
        })
      },

      // 去结算
      goSettle () {
        const shopCar = JSON.parse(JSON.stringify(this.$store.state.shopCar.shopCar))
        const shopCateId = []
        shopCar.forEach(item => shopCateId.push({ minBuy: item.minBuy, goodsId: item.goodsId, goodsName: item.goodsName }))
        const arr = shopCateId.map(item => JSON.stringify(item))
        const result = Array.from(new Set(arr)).map((item) => JSON.parse(item))
        let shopName = []
        result.forEach(item => {
          const minBuy = item.minBuy
          let quantity = 0
          shopCar.forEach(item2 => {
            if (item.goodsId == item2.goodsId) {
              quantity = item2.quantity + quantity
            }
          })
          if (minBuy > quantity) {
            shopName.push(item.goodsName)
          }
        })
        if (shopName.length) {
          let str = ''
          shopName.forEach((item, index) => {
            if (index != shopName.length-1) {
              str = str + item + ','
            } else {
              str = str + item
            }
          })
          this.$toast(str+ '商品起购数量不足')
        } else {
          this.$router.push({
            path: '/settle',
            query: {
              shopId: this.$route.query.shopId,
              shopName: this.detail.shopName,
            }
          })
        }
      }
    }
  }
</script>

<style lang="less" scoped>
.Ge-fangdajing {
  display: flex;
  align-items: center;
  height: 30px;
  background: rgb(246, 246, 246);
  margin: 0 2.6%;
  flex:1;
  border-radius: 15px;
  .iconfont{
    margin:0 8px;
  }
  span {
    font-size: 12px;
    color: rgb(185, 185, 185);
  }
}
.discount {
  display: flex;
  color: #FF5200;
  .Ge-border {
    border: 1px solid #FF5200;
    border-radius: 2px;
    display: flex;
    div {
      font-size: 12px;
      position: relative;
      span {
        margin: 0 6px;
      }
      i {
        position: absolute;
        right: -6px;
      }
    }
  }

}
.active {
  border-bottom: 1px solid red !important;
  color: #333;
}
.cateActive {
  background: #fff;
  color: #000 !important;
}
.copies {
  border: 1px solid #FFE5C8 !important;
  background: #FFE5C8;
  color: #FF5200;
}
.evaluate {
  flex: 1;
  padding: 0 4.2%;
  .evaluateBox::-webkit-scrollbar {
    display: none;
  }
}
.takeOut {
  display: flex;
  flex: 1;
  padding-bottom: 50px;
  overflow-y: auto;

  &-shopCate {
    width: 20.5%;
    height: 100%;
    background: #F6F6F6;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    p {
      height: 40px;
      font-weight: bold;
      color: #333;
      box-sizing: border-box;
      padding-left: 20%;
      padding-top: 12px;
      background: white;
    }
    &-cateOption {
      overflow: auto;
      &-name {
        padding: 11px 12.5% 11px 20%;
        color: #999;
        transition: .5s;
      }
    }
    &-cateOption::-webkit-scrollbar { 
      display: none; 
    }
  }
  &-shopList {
    flex: 1;
    p {
      height: 40px;
      padding: 0 4.5%;
      font-size: 12px;
      padding-top: 12px;
      box-sizing: border-box;
      color: #333;
    }
    &-option {
      overflow: auto;
      height: 89.823%;
      &-name {
        padding: 10px 4.5% 0;
        &-box {
          padding-bottom: 8px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #D8D8D8;
        }
        &-right {
          width: 60%;
          .van-button--mini {
            height: 16px;
          }
        }
      }
      // &-name:active {
        // background: #f2f3f5;
      // }
    }
    &-option::-webkit-scrollbar {
      display: none;
      
    }
  }
}

/deep/ .shopCartShop {
  flex: 1;
  .van-stepper__plus {
    border-radius: 4px;
    color: white;
    background: #ff5200;
    
  }
  .van-stepper__minus {
    border-radius: 4px;
    background: white;
    border: 1px solid black;
    outline: none;
  }
  .van-stepper__input {
    background: #fff;
  }
}

/deep/ .shopSpecification {
  position: relative;
  .van-overlay {
    z-index: 2200 !important;
  }
  .van-dialog {
    z-index: 2300 !important;
    .van-dialog__header {
      padding-top: 20px;
    }
  }
  .van-dialog {
    overflow: visible !important;
    .van-button--mini {
      padding: 2px 12.5px;
      
    }
  }
}

/deep/ .totalAmount {
  padding: 8px 5.3% 14px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .van-stepper__plus {
    border-radius: 4px;
    color: white;
    background: #ff5200;
  }
  .van-stepper__minus {
    border-radius: 4px;
    background: white;
    border: 1px solid black;
    outline: none;
  }
  .van-stepper__input {
    background: #fff;
  }
}
/deep/ .bujinqi {
  .van-stepper__plus {
    border-radius: 4px;
    color: white;
    background: #ff5200;
  }
  .van-stepper__minus {
    border-radius: 4px;
    background: white;
    border: 1px solid black;
    outline: none;
  }
  .van-stepper__input {
    background: #fff;
  }
}
.bottom-GoodsAction .van-goods-action-button--first {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}
.bottom-GoodsAction .van-goods-action {
  z-index: 2100;
}
.bottom-GoodsAction  {
  .van-goods-action-icon {
    transform: scale(.83);
  }
  /deep/ .van-goods-action-icon__icon {
    // color: red;
    transform: scale(1.7);
  }
}
</style>